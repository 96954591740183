<template>
<div class="modal-wrap">
  <h1 class="tit-h1 d-flex">
    {{ headerTitle }}
    <div class="ml-auto align-self-center">
      <v-icon x-small class="svg-close20" @click="closePopup"></v-icon>
    </div>
  </h1>
  <div class="modal-box">
    <div>
      <div class="d-flex gray-box">
        <div>
          <div class="form-inp sm">
            <v-select
                :items="this.$store.getters['userStore/GE_USER_ROLE'].company"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="회사구분"
                placeholder="선택하세요"
                v-model="POPUP_ASP_NEWCUST_KEY"
                readonly
            >
            </v-select>
          </div>
          <v-menu
              v-model="popDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp icon-calendar ml-2"
                  append-icon="svg-calendar"
                  label="접수일자"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                  v-model="popupDates[0]"
                  no-title
                  dark
                  color="#F7AFAE"
                  @change="startDate"
                  readonly
                  :max="popupDates[1]"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                  v-model="popupDates[1]"
                  dark
                  color="#F7AFAE"
                  no-title
                  show-current="false"
                  :min="popupDates[0]"
                  @change="endDate"
                  readonly
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                  outlined
                  class="btn-default"
                  @click="popDate = false"
              >확인</v-btn
              >
            </div>
          </v-menu>
          <div class="form-inp sm ml-2">
            <v-text-field
                class="form-inp ml-"
                name="name" label="사용자명" id="id" outlined hide-details=""
                v-model="USER_NM"
                @keyup.enter="popupClickBtn">
            </v-text-field>
          </div>
        </div>
        <div class="d-flex align-center ml-auto">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')" outlined class="btn-etc2 ml-3" @click="popupClickBtn" @keyup.enter="popupClickBtn"  >조회</v-btn>
        </div>

      </div>

      <div class="mt-3">
        <h2 class="tit-h2 d-flex">
          콜백 상세정보
        </h2>
        <div class="tab-box box-wrap">

          <table>
            <caption class="hide">
              항목
            </caption>
            <colgroup>
              <col width="120px" span="5"/>
            </colgroup>
            <thead>
            <tr>
              <th scope="col">총건수</th>
              <th scope="col">배분건수</th>
              <th scope="col">미배분건수</th>
              <th scope="col">완료건수</th>
              <th scope="col">미시도건수</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="text-center" > <v-chip class="ml-0">{{ POPUP_TOT_CNT }}</v-chip> </td>
              <td class="text-center" > <v-chip class="ml-0">{{ POPUP_DIV_CNT }} </v-chip> </td>
              <td class="text-center" > <v-chip class="ml-0">{{ POPUP_NOT_DIV_CNT }}</v-chip></td>
              <td class="text-center" > <v-chip class="ml-0">{{ POPUP_END_CNT }}</v-chip></td>
              <td class="text-center"> <v-chip class="ml-0">{{ POPUP_NOT_END_CNT }}</v-chip></td>
            </tr>
            </tbody>
          </table>

        </div>

      </div>
      <div class="mt-3">
        <h2 class="tit-h2 d-flex">
          사용자배분리스트
          <div class="ml-auto">
            <v-btn
                v-if="this.mixin_set_btn(this.$options.name, 'btnInitCnt')"
                outlined
                class="btn-point ml-2"
                @click="clearChgCntBtn"
            >초기화</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSameDist')" outlined class="btn-point ml-2" @click="equalDisBtn">균등배분</v-btn>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnDist')" outlined class="btn-point ml-2" @click="disBtn">배분</v-btn>
          </div>
        </h2>
        <div class="table-form mt-2">
          <v-data-table
              dense
              height="470px"
              fixed-header
              :headers="userPopupGridDataHeaders"
              :items="userPopupGridDataText"
              :items-per-page="30"
              item-key="index"
              :page.sync="popupPage"
              hide-default-footer
              class="grid-default"
              @page-count="popupPageCount = $event"
              show-select
              v-model="userListSelected"

              :item-class="isActiveRow"
              @click:row="rowSelect"
          >
            <template v-slot:[`item.CHG_CNT`] = "{item}">
              <v-text-field
                  class="form-inp sm ml-2"
                  name="name" outlined hide-details
                  placeholder ="0"
                  v-model="item.CHG_CNT"
                  @input="getData"
              >
              </v-text-field>
            </template>
          </v-data-table>
          <div class="grid-paging text-center pt-2">
            <v-pagination
                v-model="popupPage"
                :length="popupPageCount"
                :total-visible="popupTotalVisible"
                next-icon="svg-paging-next"
                prev-icon="svg-paging-prev"></v-pagination>
          </div>
          <p class="caption text-left">
            총<strong class="clr-error"> {{ userPopupGridDataText.length }}</strong
          >건
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import api from '@/store/apiUtil.js';
import {mixin} from '@/mixin/mixin.js';

export default {
  name: "MENU_M110303", //name은 'MENU_' + 파일명 조합
  components: {},
  mixins:[mixin], // mixin 등록
  props: {
    p01: {
      type: Boolean,
      default: false,
    },
    p02: {
      type: Boolean,
      default: false,
    },
    p03: {
      type: Boolean,
      default: false,
    },

    headerTitle: {
      type: String,
      default: "제목",
    },

    param:{
      type: Object,

    },
  },
  data() {
    return {
      footerHideTitle: '닫기',
      popDate: false,

      item: {},

      popupDates: [
        //부모창의 접수일자를 초기값으로
        this.param.sdate,
        this.param.edate,
      ],

      userPopupGridDataHeaders:[
        {
          text: 'No',
          align: 'center',
          value: 'index',
          width: '50px',
          sortable: false,
        },
        { text: '상담원명', value: 'USER_NM', align: 'left', sortable: false, },
        { text: '배분건수', value: 'DIV_CNT', align: 'center', sortable: false, },
        { text: '완료건수', value: 'END_CNT', align: 'center', sortable: false, },
        { text: '미시도건수', value: 'NOT_END_CNT', align: 'center', sortable: false, },
        { text: '추가/회수', value: 'CHG_CNT', align: 'center', sortable: false, },
      ],
      userPopupGridDataText: [],
      userListSelected: [],

      POPUP_ASP_NEWCUST_KEY: '',
      POPUP_TOT_CNT: '',
      POPUP_DIV_CNT: '',
      POPUP_END_CNT: '',
      POPUP_NOT_END_CNT: '',
      POPUP_NOT_DIV_CNT: '',

      USER_NM: '',

      popupPage: 1,
      popupPageCount: 0,
      popupItemsPerPage: 10,
      popupTotalVisible: 10,
      popupTotcount: 0,

      selectedRow: null,
    };
  },
  mounted(){
    // 헤더 초기화 및 세팅
    this.popupClickBtn();
  },
  methods: {
    startDate(e){
      this.popupDates[0] = e;
    },
    endDate(e){
      this.popupDates[1] = e;
    },
    popupClickBtn(){
      this.getUserPopupCntData();
      this.getUserPopupListData();
    },
    closePopup(){
      //배분 후 콜백접수리스트를 재조회하기 위함
      this.$emit('select');
      this.$emit('hide');
    },
    async getUserPopupCntData(){
      //콜백 상세정보 조회
      let requestData = {
        headers : {},
        sendData: {},
      }

      requestData.headers["URL"] = "/api/phone/callback/manage/callback-dstb/inqire";
      requestData.headers["SERVICE"] = "phone.callback.manage.agent-dstb";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["ASYNC"] = false;

      //부모창의 회사명으로 검색 (회사명 readonly처리)
      this.POPUP_ASP_NEWCUST_KEY = this.param.aspNewcustKey;

      //sendData 초기화
      requestData.sendData['ASP_NEWCUST_KEY'] = this.POPUP_ASP_NEWCUST_KEY;    //회사코드
      requestData.sendData['START_DATE_POP'] = this.popupDates[0];             //접수시작일자
      requestData.sendData['END_DATE_POP'] = this.popupDates[1];               //접수종료일자


      let response = await this.common_postCall(requestData);
      this.getUserPopupCntRes(response);
    },
    async getUserPopupListData(){
      //콜백상담원 배분정보 리스트
      let requestData = {
        headers : {},
        sendData: {},
      }

      requestData.headers["URL"] = "/api/phone/callback/manage/agent-dstb/inqire";
      requestData.headers["SERVICE"] = "phone.callback.manage.agent-dstb"
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["ASYNC"] = false;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 1000;

      //sendData 초기화
      requestData.sendData['ASP_NEWCUST_KEY'] = this.POPUP_ASP_NEWCUST_KEY; //회사코드
      requestData.sendData['START_DATE_POP'] = this.popupDates[0];             //접수시작일자
      requestData.sendData['END_DATE_POP'] = this.popupDates[1];               //접수종료일자
      requestData.sendData['USER_NM'] = this.USER_NM;               //접수종료일자

      // 사용하는 곳은 없으나 기존에 넘기고 있던 파라미터 (쿼리 수정 등으로 사용할 수도)
      //this.sendData['DSTR_CSLT_NM'] = '';          //배분상담원명
      //this.sendData['ORGZ_ID'] = '';               //그룹명


      //선택된 데이터 초기화
      this.userListSelected = [];

      let response = await this.common_postCall(requestData);
      this.getUserPopupListRes(response);
    },
    async registChgCnt(){
      //배분 진행
      var totCnt = this.userPopupGridDataText.length;  //사용자배분 리스트의 전체row
      var selectedDataObj = this.userListSelected;     //userListSelected (체크된 데이터)
      var data = '';
      for(var i=0; i<selectedDataObj.length; i++){
        var num = Number(selectedDataObj[i]["CHG_CNT"]);
        if(num > 0){
          data += selectedDataObj[i]["DSTR_CSLT_ID"] + ":"+ num + "/";
        }
      }

      let requestData = {
        headers : {},
        sendData: {},
      }
      //배분
      requestData.headers["URL"] = "/api/phone/callback/manage/regist";
      requestData.headers["SERVICE"] = "phone.callback.manage.regist";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["ASYNC"] = false;

      //sendData 초기화
      requestData.sendData['DSTR_INFO'] = data.substring(0, data.length-1);
      requestData.sendData['ASP_NEWCUST_KEY'] = this.POPUP_ASP_NEWCUST_KEY;    //회사구분
      requestData.sendData['START_DATE_POP'] = this.popupDates[0];             //접수시작일자
      requestData.sendData['REG_MAN'] = this.$store.getters['userStore/GE_USER_ROLE'].userId     //생성자
      requestData.sendData['CHNG_MAN'] = this.$store.getters['userStore/GE_USER_ROLE'].userId    //변경자


      await this.common_postCall(requestData);

      //배분 후 재조회
      this.getUserPopupCntData();
      this.getUserPopupListData();

    },
    getUserPopupCntRes(res){
      let header= res.HEADER;
      //this.pageCount = header.TOT_COUNT;

      let data = res.DATA;

      for(var i in data){
        this.POPUP_DIV_CNT = data[i].DIV_CNT;
        this.POPUP_END_CNT = data[i].END_CNT;
        this.POPUP_NOT_DIV_CNT = data[i].NOT_DIV_CNT;
        this.POPUP_NOT_END_CNT = data[i].NOT_END_CNT;
        this.POPUP_TOT_CNT = data[i].TOT_CNT;
      }
    },
    getUserPopupListRes(res){
        let header = res.HEADER;
        this.popupTotcount = header.TOT_COUNT;

        //초기화
        this.userPopupGridDataText = [];

        let data = res.DATA;

        for(var i = 0; i < data.length; i++){
        this.userPopupGridDataText.push({
          DSTR_CSLT_ID : data[i].DSTR_CSLT_ID,
          ORGZ_NM : data[i].ORGZ_NM,
          USER_NM : data[i].USER_NM,
          END_CNT : data[i].END_CNT,
          NOT_END_CNT : data[i].NOT_END_CNT,
          ORGZ_ID : data[i].ORGZ_ID,
          DIV_CNT : data[i].DIV_CNT,
          index : i+1,
          CHG_CNT : 0,
        });
      }
    },
    clearChgCntBtn(){
      //상담원배분팝업 > 초기화 버튼
      this.getUserPopupListData();
      //선택된 row해제
      this.userListSelected = [];
    },
    equalDisBtn(){
      // 균등배분

      var selectedDataObj = this.userListSelected;     //userListSelected (체크된 데이터)
      var chkListCnt = selectedDataObj.length;         //선택된 상담원 수
      //var totChgCnt = 0;


      //체크 된 게 없을 경우
      if(chkListCnt <= 0 ){
        this.common_alert('상담원을(를) 선택해주세요.', 'error');
        return false;
      }

      //미배분건수 - 배분할 건수
      //var divCnt = this.POPUP_NOT_DIV_CNT - totChgCnt;
      var divCnt = parseInt(this.POPUP_NOT_DIV_CNT);

      //미배분건수 - 배분할 건수가 0보다 적으면 배분할 건수가 없음을 알림과 동시에 리스트 초기화
      if(divCnt <= 0) {
        this.common_alert('배분 할 건수가 없습니다.', 'error');
        this.clearChgCntBtn();
      }

      //남은 갯수 / 선택된 상담원 수 = 평균값
      var divNum = Math.floor(divCnt / chkListCnt);

      //나머지 값
      var rmdCnt = divCnt % chkListCnt;

      for(var j=0; j<chkListCnt; j++){
        //초기화
        selectedDataObj[j]["CHG_CNT"]= 0;

        if(divNum > 0){
          selectedDataObj[j]["CHG_CNT"]= divNum;
        }
      }

      for(var j=0; j<chkListCnt; j++){
        if(rmdCnt > 0){
          rmdCnt--;
          selectedDataObj[j]["CHG_CNT"]= selectedDataObj[j]["CHG_CNT"]+1;
        }
      }

      //체크를 해제 했을 때, 기존에 배분된 추가/회수 건수 초기화
      this.notChkData();

    },
    disBtn(){
      //배분 버튼을 통해 update 진행
      var selectedDataObj = this.userListSelected;     //선택된 상담원
      var chkListCnt = selectedDataObj.length;         //선택된 상담원 수

      //input에 쓰여진 추가/회수의 총 건수 합치기
      var totChgCnt = 0;
      var notDivCnt = this.POPUP_NOT_DIV_CNT;
      for(var i in selectedDataObj){
        totChgCnt += Number(selectedDataObj[i]["CHG_CNT"]);
      }

      //1. 선택된 상담원이 없는 경우
      if(chkListCnt == 0 ){
        this.common_alert('상담원을(를) 선택해주세요.', 'error');
        return;
      }

      //2. 배분될 추가/회수 건수가 없거나 미배분건수가 0일 때 배분을 진행한 경우, alert+초기화
      if(totChgCnt == 0 || notDivCnt == 0 ){
        this.common_alert('배분 될 건수가 없습니다.', 'error');
        this.clearChgCntBtn();
      } else if (notDivCnt-totChgCnt < 0 ){
        this.common_alert('배분된 건수가 총 미배분 건수를 초과하였습니다.', 'error');
        return;
      } else{
        //배분될 건수가 있을 경우, 배분진행
        this.common_confirm('배분처리하시겠습니까?', this.registChgCnt, null, null, 'chk');
      }

      //체크를 해제 했을 때, 기존에 배분된 추가/회수 건수 초기화
      this.notChkData();

    },
    getData(){
      var totChgCnt = 0;
      //selected 초기화
      this.userListSelected = [];

      //추가/회수 에 값이 들어간 경우, selected
      for(var i in this.userPopupGridDataText){
        if(this.userPopupGridDataText[i]["CHG_CNT"] != 0){
          this.userListSelected.push(
              this.userPopupGridDataText[i]
          );
        }
      }
      for(var i=0; i<this.userListSelected.length; i++){
        totChgCnt += Number(this.userListSelected[i].CHG_CNT);
      }
     // return totChgCnt;
    },
    notChkData(){
      //체크를 해제 했을 때, 기존에 배분된 추가/회수 건수 초기화
      //현재 체크된 리스트
      var selectIdx = [];
      for(var i in this.userListSelected){
          selectIdx.push(this.userListSelected[i].index);
      };

      //전체리스트
      var listIdx = [];
      for(var i in this.userPopupGridDataText){
          listIdx.push(this.userPopupGridDataText[i].index);
      };

      //전체리스트 중 체크되지 않은 데이터
      var listData = listIdx.filter(x => !selectIdx.includes(x));

      for(var i in listData) {
          var num = listData[i]-1;
          //전체리스트 중 체크되지 않은 데이터인데 추가/회수의 값이 있을 경우 0으로
          if(this.userPopupGridDataText[num].index == listData[i] && this.userPopupGridDataText[num].CHG_CNT !=0 ){
              this.userPopupGridDataText[num].CHG_CNT = 0;
          };
      }

    },
    rowSelect(idx) {
      this.selectedRow = idx;
    },
    isActiveRow(item){
      const activeClass = item === this.selectedRow ? 'active' : '';
      return activeClass;
    },
  },
  computed: {
    dateRangeText () {
      var newStartDate = this.popupDates[0];
      var newEndDate = this.popupDates[1];
      return newStartDate + ' ~ ' + newEndDate;
    },
  },
};
</script>

<style></style>
